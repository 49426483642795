// Small device
@media (max-width: $sm) {

}

// Medium device
@media (max-width: $md) {

}

// Large device 
@media (max-width: $lg) {

}

// Extra Large device
@media (max-width: $xl) {
    .container {
        padding-left: 1rem;
        padding-right: 1rem;
    }

}
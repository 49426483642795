.miracle-movers-reputation {
    &.v4 {
        .image-wrap {
            min-height: 430px;
            .first-image {
                position: absolute;
                width: 70%;
                right: -1rem;
                height: 100%;
                object-fit: cover;
                z-index: 1;
                @media (min-width: $lg) {
                    left: 7rem;
                    width: 80%;
                
                }
            }
            .second-image {
                position: absolute;
                bottom: auto;
                left: 0;
                height: 70%;
                width: auto;
                top: 15%;
                object-fit: cover;
                z-index: 2;
                @media (min-width: $md) {
                    left: -2rem;
                    max-width: 80%;
                
                }
            }
            @media (min-width: $md) {
                max-height: 100%;
            }
        }
    }
}
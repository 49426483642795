.miracle-movers-sidebar-aside, .contactp, footer {
    .main-content {
        ul {
            br {
                display: none;
            }

            list-style: disc;
            margin-left: 2rem;
            margin-bottom: 1.3rem;

            li {
                margin-bottom: 1rem;
            }
        }

        ol {
            br {
                display: none;
            }

            list-style: decimal;
            margin-left: 2rem;
            margin-bottom: 1.3rem;

            li {
                margin-bottom: 1rem;
            }
        }
    }

    .sidebar {
        .menu {

            border-radius: 2rem;

            li {
                cursor: pointer;
                padding: .7rem .5rem;
                font-weight: 600;
                border-bottom: .5px solid;
                @apply border-white text-black;

                a:hover {
                    @apply text-secondary;
                }

                &:last-child {
                    border: none;
                }

                a {
                    @apply text-primary;
                }
            }

            .current-menu-item a {
                @apply text-secondary;
            }

        }

        .fluentform {
            .fluentform .ff-el-form-control {
                @apply bg-white text-gray;
                border-radius: 0;
                padding: .6rem 1rem;
            }

            .ff_submit_btn_wrapper {
                margin-bottom: 0 !important;

                .btn {
                    @apply font-medium;
                    margin-bottom: 0;
                    width: 100%;
                }
            }
        }
    }
    .menu-item-has-children {
        position: relative;

        a:first-child {

            &::after {
                color: #b80816;
                content: "+";
                display: inline-block;
                font-size: 20px;
                position: absolute;
                text-align: center;
                margin-left: 5px;
                margin-top: -4px;
            }

            &.active {
                &::after {
                    content: "-";
                }
            }
        }

    }

    .sub-menu {
        display: none;

        li {
            a {
                &::after {
                    display: none !important;
                }
            }
        }

    }
}

footer {
    .nav-main {
        li {
            @apply mb-4;
            a {
                @apply text-gray;
                font-weight: 400;
                &:hover {
                  @apply  text-white;
                }
                
            }
        }

         .nav-drop {
            @apply mt-4;
            display: none;
    
            li {
                a {
                    &::after {
                        display: none !important;
                    }
                }
            }
        }
    }

    .menu-item-has-children {
      

        a:first-child {

            &::after {
                color: #ffffff;
                content: "+";
                display: inline-block;
                font-size: 20px;
                position: absolute;
                text-align: center;
                margin-left: 5px;
                margin-top: -4px;
            }

            &.active {
                &::after {
                    content: "-";
                }
            }
        }

    }
}
.miracle-movers-process {
    &.v4 {
        .process-card {
            position: relative;
            overflow: hidden;
            background: white;
            transition: .25s ease-in-out;

            img {
                transition: .3s ease-in-out;
            }

            h3 {
                position: absolute;
                z-index: 10;
                left: 0;
                bottom: 0;
            }

            .overlay {
                @apply bg-light;
                content: '';
                position: absolute;
                top: 150%;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transition: .3s ease-in-out;
                scale: .7;
            }

            p {
                position: absolute;
                top: 100%;
                left: 1.5rem;
                text-align: left;
                width: 80%;
                opacity: 0;
                transition: .25s ease-in-out;

            }

            &:hover {
                .overlay {
                    top: 0;
                    scale: 1;
                }

                p {
                    opacity: 1;
                    z-index: 10;
                    top: 3rem;
                    transition: .33s ease-in-out;
                }

                img {
                    scale: .8;
                    transition: .35s ease-in-out;
                }
            }
        }
    }
}
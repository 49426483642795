.login{
    background-image:url("../images/login-bg.jpg");
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

#login {
    padding: 20vh 0 0;
    #nav, #backtoblog{
        text-align: center;
    }
    #nav a, #backtoblog a {
        @apply text-white;
        transition: .25s all;
        
        &:hover {
            @apply text-primary;
        }
    }
    .forgetmenot {
        padding-top: 10px;
    }
}
#login h1 a {
    background-image:url("../images/login.png");
    background-size: 90px;
    width: 100%;
    height: 110px;
    }
    .wp-core-ui .button.button-large{
        height: auto;
        padding: 0.2rem 1rem;
    }
    .login .message{
        @apply border-primary;
        border-left: 4px solid;
    }
    .wp-core-ui .button-primary {
    @apply bg-primary text-white font-button;
    position: relative;
    border-radius: 0;
    padding: 0.6rem 2.2rem;
    font-size: 1.2rem;
    border: 2px solid transparent;
    transition: .25s all;

      &:hover {
        @apply bg-secondary border-secondary text-white;
        cursor: pointer;
      }
}
.login form .input {
    font-size: 1.6rem;
}
}
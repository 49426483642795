//   MOBILE + TABLET WIDTH
@media screen and (max-width: $lg) {
    .mobile-menu {
        .mobile-menu-wrap {
            @apply bg-primary text-white;
            top: 4.25rem;
            left: -20rem;
            width: 20rem;
            height: 30rem;
            padding: 1.5rem;
            transition: .3s ease;
            overflow: hidden;
            .nav {
                transition: .3s ease;

                .multi-level-element {
                    transform: translateX(0);
                    transition: .3s ease;
                    width: 100%;
                }

                &.slide-back {
                    transform: translateX(-100%);
                    transition: .3s ease;
                }
            }

            .drop-menu {
                @apply bg-primary;
                height: 100%;
                width: 100%;
                left: 100%;
                transform: translateX(100%);
                transition: .4s cubic-bezier(.075, .82, .165, 1);
                opacity: 0;

                &.slide {
                    left: .5rem;
                    transition: .3s ease;
                    opacity: 1;
                    display: block;
                    transform: translateX(100%);
                }
                &.slide-left{
                    left: -100%;
                    transition: .3s ease;
                    opacity: 1;
                    display: block;
                    transform: translateX(100%);
                }
            }
            .drop-menu2 {
                @apply bg-primary;
                height: 100%;
                width: 100%;
                left: 100%;
                transform: translateX(100%);
                transition: .4s cubic-bezier(.075, .82, .165, 1);
                opacity: 0;

                &.slide {
                    left: .5rem;
                    transition: .3s ease;
                    opacity: 1;
                    display: block;
                    transform: translateX(100%);
                }
               
            }

            .nav-link {
                display: inline-block;
            }

            &.show {
                left: 0;
                transition: .3s ease;
            }
        }

        .mobile-icon-wrapper {
            z-index: 100;
            position: relative;
            width: 20px;
            height: 30px;
            top: 3px;
            display: inline-block;

            .mobile-icon {
                @apply bg-white;
                width: 20px;
                height: 2px;
                top: 12px;
                right: 0;
                position: relative;
                transition: .3s ease;
                transform: rotate(-180deg);

                &::before {
                    content: '';
                    position: absolute;
                    top: -.55rem;
                    right: 0;
                    width: 20px;
                    height: 2px;
                    background: inherit;
                    transition: .3s ease;
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: -.55rem;
                    right: 0;
                    width: 20px;
                    height: 2px;
                    background: inherit;
                    transition: .3s ease;
                }

                &.active {
                    transition: .3s ease;
                    width: 13px;


                    &::before {
                        width: 9px;
                        transition: .3s ease;
                    }

                    &::after {
                        width: 18px;
                        transition: .3s ease;
                    }
                }
            }

            &:hover {
                cursor: pointer;
            }
        }

        // Mobile icon wrapper end
        &.v1 {
            .mobile-menu-wrap {
                right: -20rem;
                top: 5.2rem;


                &.show {
                    right: 0;
                    transition: .3s ease;
                }
            }

            .mobile-icon {
                @apply bg-secondary;
                left: 0;
                

                &::before,
                &::after {
                    left: 0;
                }
            }
        }
        &.v2 {
            .mobile-menu-wrap {
                
                top: 7.4rem;
                height: 100vh;
                right: -100rem; 
                width: 100%;

                &.show {
                    right: 0;
                    transition: .3s ease;
                }
                @media screen and (max-width: $sm) {
                top: 8.4rem;
                }
            }

            .mobile-icon {
                left: 0;
                // background: $dark-blue;

                &::before,
                &::after {
                    left: 0;
                }
            }
        }
        &.v4 {
            .mobile-menu-wrap {
                left: -20rem;
                top: 6.2rem;

                &.show {
                    left: 0;
                    transition: .3s ease;
                }
                @media screen and (max-width: $sm) {
                top: 4.3rem;
                }
            }

            .mobile-icon {
                @apply bg-white;
                right: 0;

                &::before,
                &::after {
                    right: 0;
                }
            }
        }
    }

}


// MOBILE AND TABLET END
@media screen and (max-width: $md) {
    .mobile-menu {
        &.v1 {
            .mobile-menu-wrap {
                right: -50rem;
                height: 100vh;
                width: 100%;
            }
        }
        &.v2 {
            .mobile-menu-wrap {
                right: -50rem;
                height: 100vh;
                width: 100%;
            }
        }
        &.v4 {
            .mobile-menu-wrap {
                left: -50rem;
                height: 100vh;
                width: 100%;
            }
        }
    }
}
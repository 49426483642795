a {
    text-decoration: none;
    color: inherit;
    i {
        color: inherit;
    }
}

.btn {
    @apply font-button;
    transition: all .3s;
    text-decoration: none;
    display: inline-block;
    width: max-content;
    padding: .75rem 2.3rem;
    text-transform: uppercase;
    font-size: 1.1rem;
}

.btn-primary {
    @extend .btn; 
    @apply bg-primary text-white;    
    transition: all .3s;
    border: none;
    &:hover {
        @apply bg-secondary text-white;
    }
}
.btn-secondary {
    @extend .btn;
    @apply bg-secondary;
    border: 1px solid transparent;

    &:hover {
        @apply bg-base border-gray text-white;
        cursor: pointer;
        border: 1px solid;
    }
}


.btn-ghost {
    @extend .btn;
    @apply border-white text-white;
    border: 1px solid;
    position: relative;

    &:hover {
        @apply bg-white text-black border-black shadow-lg;
        cursor: pointer;
        
        .iconbtn {
            @apply fill-black;
        }
    }
    &_light{
        @extend .btn-ghost;
        @apply border-white text-white;        
    }
    &_light:hover{
        @apply border-primary text-primary;
    }

}
.btn-link {
    color: inherit;
    text-decoration: none;
    text-transform: uppercase;
    &__primary{
        @apply text-primary;
        &:hover {
            @apply text-primary;
        }
    }
}




// ICONS

.icon-circle-primary {
    @apply border border-primary;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 2.2rem;
    height: 2.2rem;
    border-radius: 50%;
    &__big {
        @extend .icon-circle-primary;
        width: 3rem;   
        height: 3rem;   
    }
}
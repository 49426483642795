    .section-spacing {
        padding-top: 80px;
        padding-bottom: 80px;

        &-medium {
            padding-top: 80px;
            padding-bottom: 80px;
        }
        &-small {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        &-big {
            padding-top: 120px;
            padding-bottom: 120px;
        }
        &.pb-0 {
            padding-bottom: 0 !important;
        } 

    }
    
    .miracle-movers-h {
        &__250 {
            height: 250px;
        }

        &__350 {
            height: 350px;
        }

        &__450 {
            height: 450px;
        }

        &__550 {
            height: 550px;
        }

        &__600 {
            min-height: 600px;
        }

        &__700 {
            min-height: 700px;
        }

        &__800 {
            min-height: 800px;
        }

        &__900 {
            height: 100vh;
        }

        &__1000 {
            min-height: 1000px;
        }

        &__full {
            min-height: 100vh;
        }
    }

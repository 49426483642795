// overlays
.baner-form {
    &-ghost {
        .fluentform .ff-el-form-control {
            @apply border-white text-white;
            border-radius: 0;
            background: transparent;
            border: 1px solid;
        }
    }

    &-underline_white {
        .fluentform .ff-el-form-control {
            @apply border-white text-white;
            border-radius: 0;
            background: transparent;
            border-top: none;
            border-right: none;
            border-left: none;
        }
    }

    &-underline_dark {
        .fluentform .ff-el-form-control {
            @apply border-gray text-gray;
            border-radius: 0;
            background: transparent;
            border-top: none;
            border-right: none;
            border-left: none;
        }
    }

    &.v2 {
        @apply border-white/20 bg-white/20;
        padding: 2rem;
        border-radius: 5px;
        border: 1px solid;
    }

    &.v4 {
        .fluentform .ff-el-form-control {
            text-align: center;
        }
    }
}

.miracle-movers-mainform {

    &.v1 {
        .choices__inner {
            text-align: left;
        }

        .choices__list--dropdown {
            overflow-y: hidden;
            text-align: left;
        }

        .ff_submit_btn_wrapper {
            text-align: center;
        }
    }

    &.v2 {
        .choices__inner {
            text-align: left;
        }

        .choices__list--dropdown {
            overflow-y: hidden;
            text-align: left;
        }

        .ff_submit_btn_wrapper {
            text-align: center;

            .ff-btn-submit {
                width: 100%;
            }
        }
    }

    &.v3 {
        .choices__inner {
            text-align: left;
        }

        .choices__list--dropdown {
            overflow-y: hidden;
            text-align: left;
        }

        .ff_submit_btn_wrapper {
            text-align: center;

            .ff-btn-submit {
                width: auto;
            }
        }
    }

    &.v4 {
        .choices__inner {
            text-align: left;
        }

        .choices__list--dropdown {
            overflow-y: hidden;
            text-align: left;
        }

        .fluentform .ff-el-form-control,
        .frm-fluent-form .choices__inner {
            @apply border-gray text-gray;
            border-radius: 0;
            background: transparent;
            border-top: none;
            border-right: none;
            border-left: none;
            border-bottom: 1px solid;
        }
    }
}


.fluentform {

    @media only screen and (max-width: 600px) {
        .ff_columns_total_2 {
            @apply flex-col-reverse;
        }
          .step-nav .ff-btn-prev, .ff-btn-submit, .step-nav .ff-btn-next  {
            width: 100%;
            margin-bottom: 20px;
        }
      }

      .ff-btn-submit {
        padding-top: 12px;
        padding-bottom: 12px;
        text-transform: uppercase;
        border-radius: 20px!important;
        font-family: Be Vietnam Pro Medium,sans-serif!important;
        font-size: 14px!important;
    }

    .ff-el-progress {
        background-color: #ffffff6e;
    }
    .ff-el-progress-status {
        color: white;
    }
    .ff-el-form-control {
        @apply bg-white text-gray;
        border-radius: 0;
        padding: .6rem 1rem;
    }

    .ff_submit_btn_wrapper {
        margin-bottom: 0 !important;

        .btn {  
            @apply font-medium;
            margin-bottom: 0;
            width: 100%;
        }
    }

    // Multi step
    .step-nav {
        .ff-btn {
            @extend .btn;
        }

        .ff-btn-prev {
            padding-left: 56px;
        }

        .ff-btn-next {
            padding-right: 56px;
        }

        .ff-btn-next,
        .ff-btn-prev {
           
            @apply bg-secondary;
            @apply text-white;
            @apply text-[14px];
            @apply rounded-full;
            position: relative;

            &:hover {
                @apply shadow-2xl;
            }

            &::after {
                content: "→";
                display: block;
                height: 20px;
                position: absolute;
                right: 23px;
                width: 20px;
                top: 7px;
                font-size: 21px;
            }
        }

        .ff-btn-prev::after {
            left: 22px;
            right: auto;
            transform: rotate(180deg);
            top: 17px;

        }
    }

    .ff-el-progress-bar {
        @apply bg-secondary #{!important};
    }
    .ff-el-input--label label {
        color: white;
    }
    form.ff-form-has-steps .ff-btn-submit {
        padding: 0.75rem 2.3rem;
    }
}
#fluentform_24 {
    max-width: 800px;
    @apply mx-auto;
}
.fluentform_wrapper_23 {
    .ff-el-input--label label {
        color: white;
    }
    .ff-el-form-control {
        border-radius: 5px;
        height: auto!important;
    }
    .ff_submit_btn_wrapper {
        text-align: right!important;
    }
   .ff-btn-submit{
    padding: 0.75rem 2.3rem;
    padding-right: 56px;
    background-color: #B80817!important;
    &:after {
        content: "→";
        display: block;
        font-size: 21px;
        height: 20px;
        position: absolute;
        right: 23px;
        top: 7px;
        width: 20px;
    }
}
}
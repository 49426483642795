// EXTRAS
.underline-primary {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: -0.3rem;
    left: 0;
    width: 100%;
    height: 2px;
    @apply bg-secondary;
  }

  &_short {
    @extend .underline-primary;

    &::after {

      width: 25%;
      max-width: 180px;
      @apply mx-auto;

      @media (min-width: $md) {
        left: 0;
      }

    }
  }

  &_short_light {
    @extend .underline-primary;

    &::after {
      width: 50%;
      @apply bg-primary/40;
    }
  }
}

.gbadge {
  width: max-content;
  box-shadow: 1px 1px 12px 5px #5b5b5b38;
  padding: 10px 20px;
  margin: 5px;
  position: absolute;
  z-index: 9;
  left: -40px;
  top: 20px;

  @media (max-width: $md) {
    left: 5%;
    font-size: 12px;
  }

  @media (max-width: $sm) {
    display: none;
  }
}

.s-item {
  position: relative;
}

.s-item::after {
  background: url(/wp-content/uploads/2022/10/arrow-right-long.svg);
  background-repeat: no-repeat;
  content: "";
  height: 31px;
  position: absolute;
  right: 27px;
  top: 16px;
  width: 40px;
}

.s-item svg {
  fill: red;
}

.questions-wrapper input {
  display: none;
}

/* Accordion styles */
.tabs {

  overflow: hidden;

}

.tab {

  width: 100%;
  @apply text-primary;
  overflow: hidden;

  &-label {
    display: flex;
    justify-content: space-between;
    padding: 1em;
    background: #f0f5fa;
    font-weight: bold;
    cursor: pointer;
    margin-bottom: 20px;

    /* Icon */
    &:hover {
      background: darken(#f0f5fa, 10%);
    }

    &::after {
      content: "\276F";
      width: 1em;
      height: 1em;
      @apply text-secondary;
      text-align: center;
      transition: all .35s;
    }
  }

  &-content {
    max-height: 0;
    padding: 0 1em;
    @apply text-dark;
    background: white;
    transition: all .35s;
    overflow: hidden;
  }

  &-close {
    display: flex;
    justify-content: flex-end;
    padding: 1em;
    font-size: 0.75em;
    background: #f0f5fa;
    cursor: pointer;

    &:hover {
      background: darken(#f0f5fa, 10%);
    }
  }
}

// :checked
input:checked {
  +.tab-label {
    background: darken(#f0f5fa, 10%);

    &::after {
      transform: rotate(90deg);
    }
  }

  ~.tab-content {
    max-height: 100vh;
    padding: 1em;
  }
}

.row {
  display: flex;

  .col {
    flex: 1;

    &:last-child {
      margin-left: 1em;
    }
  }
}

// Photoaside

.line-r::after {
  content: '';
  border-bottom: 3px solid #b80816;
  width: 25%;
  margin-top: 1rem;
  max-width: 180px;
  text-align: left;
  display: block;
}

@media only screen and (min-width: 1024px) {

  .current-menu-parent,
  .current-menu-item {
    position: relative;
    ;
  }

  .desktop-menu {

    .current-menu-parent::after,
    .current-menu-item::after {
      border-bottom: 3px solid #fff;
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      bottom: -46px;

    }

  }

  .acf-nav-menu .current-menu-item::after {
    display: none;
  }
}

@media only screen and (max-width: 1023px) {
  .freemestimate .line-r::after {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .line-r::after {
    margin-left: auto;
    margin-right: auto;
  }
}

@media only screen and (max-width: 767px) {
  .miracle-movers-sidebar-aside .menu-wrapper .line-r::after {
    margin-left: 0;
  }

}

.short-cta {
  padding-bottom: 0;
  padding-top: 2rem;
}

.miracle-movers-simplecontent {

  h2,
  h3 {
    @apply font-tagline text-primary;
  }

  a:hover {
    @apply text-secondary;
  }
}

.smap a:hover {
  @apply text-secondary;
}

.careers {
  .w-8 {
    width: 4rem;
  }

  .h-8 {
    height: 4rem;
  }
}

.franchise {
  .w-8 {
    width: 4rem;
  }

  .h-8 {
    height: 4rem;
  }
}

.home-mainhero {
  h1 {
    line-height: normal;

    @screen md {
      @apply text-[80px];
    }


    span {
      display: block;
    }
  }

}

.franchiseform {

  h2,
  h3 {
    color: white;
  }

  h2 {
    @apply font-tagline text-3xl;

    @screen md {
      @apply text-5xl;
    }
  }

  .ff-el-form-control {
    background: white !important;
    border-radius: 5px !important;
  }

  .ff_submit_btn_wrapper .ff-btn-submit {
    background-color: rgba(184, 8, 23, 1);
    color: rgba(255, 255, 255, 1);
    padding: 12px 30px;
  }
}

#locationSwitch {
  padding: 10px;
}

.cou-circle {
  background-image: url('/wp-content/uploads/2023/03/texture_counters.png');
  position: relative;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: #023683;
    opacity: 0.97;
    position: absolute;
    border-radius: 100%;
    z-index: 1;
  }
}

.bull {

  ul,
  ol {

    list-style-type: disc;

    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;

  }
}

.acf-map {
  width: 100%;
  height: 700px;
  border: #ccc solid 1px;
  margin: 20px 0;
}

.marker-link {
  font-weight: bolder;
  font-size: 1rem;
  display: flex;
  align-items: center;
  margin-top: 5px;

  &:hover {
    @apply text-secondary;

  }

  &::after {
    content: '';
    background: url('/wp-content/uploads/2022/10/arrow-right-long.svg');
    display: block;
    width: 25px;
    height: 15px;
    margin-left: 5px;
    background-repeat: no-repeat;
  }
}


//Navbar

#logoAndNav {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 1000;
  
  &.is-hidden {
    opacity: 0;
    -webkit-transform: translate(0,-60px);
    -webkit-transition: -webkit-transform .2s,background .3s,color .3s,opacity 0 .3s;
  }
  
  &.is-visible {
    opacity: 1;
    -webkit-transform: translate(0,0);
    -webkit-transition: -webkit-transform .2s,background .3s,color .3s;
  }

}

.ser-line {
  display: flex;
  align-items: center;
  &::before {
    content: '';
    display: block;
    border: 1px solid black;
    width: 40px;
    height: 1px;
    margin-right: 7px;
  }
  &:hover {
    &::before {
      border: 1px solid #023683;
    }
  }
}
@screen md {
  .sf-content {
    position: sticky;
    top: 140px;
  
  }
}

.allservices {
  background-position: center left;
  background-repeat: no-repeat;
  background-image: none;
  @screen lg {
    background-image: url('/wp-content/uploads/2023/03/MiracelMoversConv-1.png');
    }
}

.miracle-movers-main-banner .video-background {
  background: #000;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
.miracle-movers-main-banner video {
  -o-object-fit: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media (max-width: 640px) { 
  .mrm-timeline .w-20 {
      width: 50px;
      margin-left: -7px;
      margin-right: 5px;
    }
    
    .mrm-timeline .h-20 {
      height: 50px;
    }
    .mrm-timeline .w-5\/12 {
      width: auto;
    }
    .mrm-timeline .left-2\/4 {
      left: 5%;
    }
    .mrm-timeline .flex-row-reverse {
      flex-direction: inherit;
    }
    .mrm-timeline .year-number {
        padding: 9px;
    }
}

.state-item:last-child {
  @apply border-l border-secondary;
  border-right: none;
}
.logo {
    max-width: 270px;
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    z-index: 100;
}
.big-logo {
    @extend .logo;
    width: 200px;
}
a, img {
    transition: all .25s;
}
.responsive-image {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
.image-cover{
    object-fit: cover;
}
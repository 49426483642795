// Work Sans Font
@font-face {
    font-family: Petrona Normal;
    src: url('../fonts/Petrona/petrona-v28-latin-500.woff2') format('woff2')
  }
@font-face {
    font-family: Petrona Light;
    src: url('../fonts/Petrona/petrona-v28-latin-regular.woff2') format('woff2')
  }

// Be Vietnam Pro Font

@font-face {
    font-family: Be Vietnam Pro Light;
    src: url('../fonts/beVietnamPro/BeVietnamPro-300.woff2') format('woff2')
  }
@font-face {
    font-family: Be Vietnam Pro Normal;
    src: url('../fonts/beVietnamPro/BeVietnamPro-400.woff2') format('woff2')
  }
@font-face {
    font-family: Be Vietnam Pro Medium;
    src: url('../fonts/beVietnamPro/BeVietnamPro-500.woff2') format('woff2')
  }
  @font-face {
    font-family: Be Vietnam Pro Semibold;
    src: url('../fonts/beVietnamPro/BeVietnamPro-600.woff2') format('woff2')
  }
  
html {
    overflow-x: hidden;
}
body {
    @apply bg-white font-body;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 1;
    transition: .27s cubic-bezier(0.345, 0.545, 0.255, 1);
    animation: fade cubic-bezier(0.645, 0.45, 0.355, 1) .65s;

    padding: 0;
    margin: 0;

    &.mobile-menu-opened {
        @screen md {
            max-height: auto;
        }
    }

    @keyframes fade {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
}


:focus {
    outline: none;
}

h2 {
    font-size: 2rem;
}
h3 {
    font-size: 1.5rem;
}
.miracle-movers-about__v3 {
    padding: 6rem 0;
}
.miracle-movers-about__v4 {
max-height: 1000px;
}
.miracle-movers-about__v5, .miracle-movers-about__v8{
    .section-image {
        max-height: 900px;
    }
}
.miracle-movers-about__v7 {
    img {
        &:hover{
            transform: scale(1.01);
        }
    }
}
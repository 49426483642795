.miracle-movers-services {
    &.v2 {
        .service-photo {
            position: relative;
            overflow: hidden;
            transition: .25s ease-in-out;

            &::before {
                position: absolute;
                content: '';
                background-image: linear-gradient(to top, rgba(0, 0, 0, .34) 36%, rgba(255, 255, 255, 0) 70%);
                width: 100%;
                height: 100%;
                z-index: 1;
                display: block;
                left: 0;
                top: 0;
            }

            &:hover {
                img {
                    transition: .25s ease-in-out;
                    scale: 1.05;
                }
            }

            h3 {
                position: absolute;
                bottom: 1.5rem;
                left: 1.5rem;
                z-index: 100;
            }

        }

        .service-description {
            flex-grow: 1;
        }
    }

    &.v4 {
        .service-card {
            position: relative;
            overflow: hidden;
            background: white;
            transition: .25s ease-in-out;

            img {
                transition: .3s ease-in-out;
            }

            h3 {
                position: absolute;
                z-index: 10;
                left: 0;
                bottom: 0;
            }

            .overlay {
                @apply bg-light;
                content: '';
                position: absolute;
                top: 150%;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transition: .3s ease-in-out;
                scale: .7;
            }

            p {
                position: absolute;
                top: 100%;
                left: 1.5rem;
                text-align: left;
                width: 80%;
                opacity: 0;
                transition: .25s ease-in-out;

            }

            &:hover {
                .overlay {
                    top: 0;
                    scale: 1;
                }

                p {
                    opacity: 1;
                    z-index: 10;
                    top: 3rem;
                    transition: .33s ease-in-out;
                }

                img {
                    scale: .8;
                    transition: .35s ease-in-out;
                }
            }
        }
    }

    &.v3 {
        .service-card {
            position: relative;
            overflow: hidden;
            background: white;
            transition: .25s ease-in-out;
            border-radius: 1rem;

            img {
                transition: .3s ease-in-out;
                z-index: 1;
                position: relative;
                top: 0;
                left: 0;
            }

            h2 {
                @apply text-white;
                position: absolute;
                z-index: 10;
                left: 1rem;
                bottom: 1rem;
            }

            .overlay {
                @apply bg-black;
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 100%;
                height: 100%;
                transition: .3s ease-in-out;
                scale: .95;
                z-index: 2;
                opacity: 0;
                border-radius: 2.5rem;
            }

            p {
                @apply text-white;
                position: absolute;
                top: 18%;
                left: 2rem;
                text-align: left;
                width: 80%;
                opacity: 0;
                transition: .25s ease-in-out;

            }

            &:hover {
                .overlay {
                    top: 0;
                    scale: 1;
                    opacity: .8;
                    border-radius: 0rem;
                    transition: .22s ease-in-out;
                }

                p {
                    opacity: 1;
                    z-index: 10;
                    top: 3rem;
                    transition: .33s ease-in-out;
                }

                img {
                    scale: 1.05;
                    transition: .35s ease-in-out;
                }
            }
        }
    }
}

.miracle-movers-areas {
    background-position: center 200px;
    background-repeat: no-repeat;
    background-size: 500px;
}


@media only screen and (min-width: 200px) {
    .area-item {
        position: absolute;
    }

    .area-item:hover .dot-item::before {
        content: "";
        display: block;
        height: 125px;
        width: 125px;
        opacity: 0.35;
        border-radius: 50%;
        position: absolute;
        background: rgb(255, 255, 255);
        background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(2, 54, 131, 1) 78%);
        -webkit-animation: area-pulse 1s ease-out;
        -moz-animation: area-pulse 1s ease-out;
        -o-animation: area-pulse 1s ease-out;
        animation: area-pulse 0.8s ease-out;
    }



    .s-carolina-list,
    .n-carolina-list {
        position: absolute;
        top: 350px;
        left: 85%;
        background: #2d5195;
        padding: 10px;
        width: max-content;
        display: none;
        z-index: 9;
    }

    .s-carolina-list::before,
    .n-carolina-list::before {
        border-bottom: 10px solid #2d5195;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        content: "";
        display: block;
        height: 0;
        width: 0;
        position: absolute;
        top: -10px;

    }

    .pennsylvania {
        left: 69%;
        top: 58px;
    }

    .north-carolina {
        left: 72%;
        top: 162px;

        .a-item {
            flex-direction: row;
        }
    }

    .florida {
        left: 71%;
        top: 329px;
    }

    .south-carolina {
        left: 71%;
        top: 202px;
        .a-item {
            flex-direction: row;
        }
    }

    .atlanta-ga {
        left: 70%;
        top: 316px;
    }

    .louisiana {
        left: 55%;
        top: 287px
   
    }


  

    .miracle-movers-areas {
        background-size: 750px;
    }
}
@media only screen and (min-width: 1024px) {
.service-description:nth-child(2),
.service-description:nth-child(5) {
    @apply border-r;
    @apply border-secondary;
    @apply border-l;

}
.service-description:nth-child(8) {
    @apply border-l border-secondary;
  
}
}
@media only screen and (min-width: 1201px) {
    .n-carolina-list {
        columns: 3;
        column-count: 3;
        width: auto;
        top: 295px;

    }
}

@media only screen and (max-width: 457px) {
    .miracle-movers-areas {
        background-size: 100%;
        background-position: center 220px;
    }
}

@media only screen and (max-width: 1476px) {
    .miracle-movers-areas {
        // background-size: 1000px;
        // background-position: 50px 200px;
    }

    .areascont {
        // margin-left: -50px;
    }
}

@media only screen and (max-width: 1300px) {
    .miracle-movers-areas {
        // background-size: 1000px;
        // background-position: -100px 200px;
    }

    .areascont {
        // margin-left: -200px;
    }
}

@media only screen and (max-width: 1200px) {

    .area-item {
        position: relative;
        left: 0 !important;
        top: unset !important;
    }

    .areascont {
        margin-left: auto;
    }

   

    .north-carolina .a-item,
    .south-carolina .a-item {
        flex-direction: column;
    }

    .area-item .dot-item::before,
    .area-item:hover .dot-item::before {
        animation: none;
        opacity: 1;
        background-image: url(/wp-content/uploads/2022/11/location-dot-solid.svg);
        content: "";
        display: block;
        position: absolute;
        width: 40px;
        height: 53px;
        background-repeat: no-repeat;
        bottom: 33px;
    }

    .areascont {
        display: grid;
        grid-template-columns: auto auto auto;
        gap: 20px;
    }

    .area-item .dot-item img {
        opacity: 0;
    }

    .s-carolina-list {
        left: 42%;
        top: 208px;
    }

    .n-carolina-list {
        top: 208px;
        left: 10%;
        column-count: 2;
        width: min-content;
    }

}

@media only screen and (max-width: 768px) {
    .n-carolina-list {
        top: 225px;
        left: 63%;
    }

    .s-carolina-list {
        left: 15%;
        top: 352px;
    }

    .areascont {
        display: grid;
        grid-template-columns: auto auto;
        gap: 30px;
    }
}

@media only screen and (max-width: 640px) {
    .n-carolina-list {
        left: 37%;
        top: 441px;
    }
    .s-carolina-list {
        left: 37%;
        top: 558px;
    }
  
}
@media only screen and (max-width: 400px) {
    .areascont {
        display: grid;
        grid-template-columns: auto;
        gap: 20px;
    }
}

@keyframes area-pulse {
    0% {
        transform: scale(0.1);

    }

    25% {
        transform: scale(0.4);

    }

    50% {
        transform: scale(0.8);

    }

    100% {
        transform: scale(1);

    }
}
.miracle-movers-areas {
    .overlay {
        background-color:#f9fcff; opacity:0.90; background-blend-mode: multiply;
    }
    background-image: url('/wp-content/uploads/2023/03/blurred-red-pins-map.jpg')!important;
    background-color: whitesmoke;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}
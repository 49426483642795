#wpbody-content {
  p {
    margin: 1em 0;
  }
  .inline {
    display: inline-block !important;
    &.hidden {
      display: none !important;
    }
  }
   .toggle-auto-update{
    text-decoration: underline;
  }
  .mx-auto {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .text-white {
    color: rgb(255 255 255 / var(--tw-text-opacity)) !important;
  }
  .swiper-wrapper {
    display: flex;
    justify-content: center;
    align-items: start;
  }
  .miracle-movers-h__350.responsive-image {
    height: 350px !important;
  }
  .miracle-movers-h__350 {
    height: 350px !important;
  }
  .responsive-image {
    height: 100% !important;
  }
  
  @media (min-width: 1024px){
    .lg\:block {
      display: block !important;
    }
    .lg\:flex {
    display: flex !important;
    }
  }
  .miracle-movers-faq .questions-wrapper {
      max-height: 500px !important;
      overflow-y: scroll;
  }
  .miracle-movers-testimonials.v3 {
    .swiper-slide {
      width: 25% !important;
      margin-right: 1rem;
      p {
        height: 300px;
        overflow-y: scroll;
      }
    }
  }
  .miracle-movers-testimonials.v4 .swiper2 {
    flex-direction: column !important;
    max-height: 500px !important;
    overflow-y: scroll;
    .swiper-wrapper {
      flex-direction: column !important;
    }
    .swiper-slide {
      width: 100% !important;
    }
  }
}

.block-editor__container {
    #adminmenu,
    .edit-post-layout__metaboxes,
    .edit-post-sidebar {
        h1,
        h2,
        h3,
        h4 {
            @apply font-body;
        }
    }
}
.block-editor-block-list__layout .block-editor-block-list__block {
    max-width: 90%;
    h1,h2,h3,h4,h5,h6 {
        @apply font-header;
        color: inherit;
        margin: 0 0 1rem;
    }

    .frm-fluent-form .ff-t-container {
        display: table;
        table-layout: fixed;
        vertical-align: top;
        width: 100%;
      }
      .frm-fluent-form .ff-t-cell {
        display: table-cell;
        padding: 0 15px;
          padding-right: 15px;
          padding-left: 15px;
        vertical-align: inherit;
        width: 100%;
        margin-bottom: 1rem;
      }
      .frm-fluent-form .ff-t-cell {
        display: table-cell;
        padding: 0 15px;
          padding-right: 15px;
          padding-left: 15px;
        vertical-align: inherit;
        width: 100%;
        margin-bottom: 1rem;
      }
        .ff-el-form-hide_label > .ff-el-input--label{
          display: none;
      }
      .frm-fluent-form .ff-t-cell:first-of-type {
        padding-left: 0;
      }
      .frm-fluent-form .ff-t-cell:last-of-type {
        padding-right: 0;
      }
      .fluentform .ff-el-group {
        margin-bottom: 20px;
      }
      .fluentform .ff-el-form-control {
        padding: .6rem 1rem;
        display: block;
        font-size: 16px;
        line-height: 1.5;
        margin-bottom: 0;
        max-width: 100%;
        padding: 6px 12px;
        transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
        width: 100%;
      }
      
      .ff_submit_btn_wrapper {
          margin-top: 2rem;
      }
}

.block-editor-block-list__layout .block-editor-block-list__block[data-align="full"]>.block-editor-block-list__block-edit figure {
    width: inherit;
}

//gutenberg side-bar width
.edit-post-layout.is-sidebar-opened .edit-post-plugin-sidebar__sidebar-layout,
.edit-post-layout.is-sidebar-opened .edit-post-sidebar, .interface-complementary-area {
    min-width: 520px !important;
}

.interface-interface-skeleton__sidebar .interface-complementary-area {
    width: 100%;
}

.edit-post-layout:not(.is-sidebar-opened) .interface-interface-skeleton__sidebar {
    display: none;
}

.is-sidebar-opened .interface-interface-skeleton__sidebar {
    width: 650px;
}

.acf-block-preview .overlay,
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &+.container {
        position: relative;
        z-index: 2;
    }
}
.editor-styles-wrapper p {
  margin-top: 0 !important;
}

#adminmenu li.wp-menu-separator {
  @apply bg-light;
  height: 1px;
  opacity: .3;
}

.theme-browser .theme .theme-name {
  height: 100%;
}
.ai1wm-progress-bar-percent {
  font-size: .9em !important;
}
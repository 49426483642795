@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

$sm: 640px;
$md: 768px;
$lg: 1024px;
$xl: 1280px;
$xxl: 1536px;

@import 'plugins/swiper';

@import 'components/fonts';
@import 'components/colors';
@import 'components/images';
@import 'components/typography';
@import 'components/buttons';
@import 'components/spacing';
@import 'components/grid';
@import 'components/menu';
@import 'components/mobile';
@import 'components/responsive';
@import 'components/transitions';
@import 'components/extras';
@import 'components/forms';
@import 'components/wplogin';
@import 'components/wpadmin';

@import 'core/core';

@import 'layout/google-rating';
@import 'layout/main_banner';
@import 'layout/banner';
@import 'layout/services';
@import 'layout/reputation';
@import 'layout/testimonials';
@import 'layout/process';
@import 'layout/sidebaraside';



@import 'layout/review';
@import 'layout/about';
@import 'layout/offer';
@import 'layout/contact';
@import 'layout/footer';


.miracle-movers-main-banner {
    &.mainhero {
        // margin-top: -120px;
    }
    @media screen and (max-width: 640px) {
        &.mainhero {
            // margin-top: -186px;
        }
    }
    &.v4 {
        .mobile-overlay{
            position: absolute;
            &::after {
                content: '';
                position: absolute;
                background: rgba(255,255,255,.9);
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                z-index: 1;
            }
            @media (min-width: $lg) {
                position: relative;
                &::after {
                    content: none;
                }
            }
        }
    }
}
#logoAndNav::after {
    background-color: #fff;
    content: " ";
    height: 2px;
    opacity: 0.5;
    width: 100%;
    z-index: 2;
    position: absolute;
}
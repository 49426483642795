.menu-wrap {
    @apply font-menu;
    position: relative;
    display: flex;
    flex-direction: row;
    box-shadow: none;
    width: auto;
    height: auto;
    background: transparent;
    right: 0;
    padding: 0;
    align-items: center;

    nav {
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;
        flex-direction: row;


        .top-menu-link {
            margin-bottom: 0;
            transition: .3s ease;
            margin-right: 1.1rem;

            span:hover,
            a:hover {
                opacity: 0.85;;
                transition: .3s ease;
            }

            .drop-menu {
                width: max-content;
                display: none;
                border: 1px solid white;
                border-radius: 4px;
                white-space: nowrap;
               
               

                .dropdown-link {
                    @apply text-white;

                    &:hover {
                        @apply text-white;
                    }
                }

                span:hover,
                a:hover {
                    @apply text-light;
                    margin-left: 2px;
                    margin-right: -2px;
                    transition: .3s ease;
                    text-decoration: underline;
                }
            }

            &:hover {
                @apply text-primary;
                transition: .3s ease;
                position: relative;

                .drop-menu {
                    @apply bg-black;
                    position: absolute;
                    top: 2.2rem;
                    left: .3rem;
                    opacity: 1;
                    display: block;
                    padding: 1rem;
                    opacity: 0.95;
                    border-radius: 2px;
                    box-shadow: -1px 11px 18px -2px rgba(0, 0, 0, 0.75);
                    z-index: 2;

                    >nav-link {
                        @apply text-black;
                    }

                    &::before {
                        content: '';
                        top: -1rem;
                        left: 0;
                        height: 1.5rem;
                        width: 100%;
                        background: transparent;
                        position: absolute;
                    }
                }
            }
        }

        .top-menu-link2 {
            margin-bottom: 0;
            transition: .3s ease;
            margin-right: 1.1rem;

            .drop-menu2 {
                width: max-content;
                display: none;
                border: 1px solid white;
                border-radius: 4px;

                .dropdown-link {
                    @apply text-white;

                    &:hover {
                        @apply text-light;
                    }

                    span:hover,
                    a:hover {
                        @apply text-light;
                        transition: .3s ease;
                    }
                }
            }

            &:hover {
                @apply text-primary;
                transition: .3s ease;
                position: relative;

                .drop-menu2 {
                    @apply bg-black;
                    position: absolute;
                    top: 1.2rem;
                    left: 4rem;
                    opacity: 1;
                    display: block;
                    padding: 1rem;
                    opacity: 0.95;
                    border-radius: 2px;
                    box-shadow: -1px 11px 18px -2px rgba(0, 0, 0, 0.75);
                    z-index: 2;

                    >nav-link {
                        @apply text-black;
                    }

                    &::before {
                        content: '';
                        top: -1rem;
                        left: 0;
                        height: 1.5rem;
                        width: 100%;
                        background: transparent;
                        position: absolute;
                    }
                }
            }
        }

    }

}
.miracle-movers-testimonials {
    &.v3 {
        .swiper3 {
            width: 100%;
            overflow: hidden !important;
            .swiper-slide {
                text-align: center;
                font-size: 18px;
            }

            .swiper-pagination {
                bottom: -2rem;
                position: absolute;

                .swiper-pagination-bullet {
                    @apply bg-white;

                    &-active {
                        opacity: 1;
                    }
                }
            }
        }
    }
    &.v4 {
        .swiper2 {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }

        .swiper-slide {
            min-height: 280px;
            width: 80%;
            text-align: center;
            font-size: 18px;

            /* Center slide text vertically */
            display: -webkit-box;
            display: -ms-flexbox;
            display: -webkit-flex;
            display: flex;
            flex-direction: column;
            -webkit-box-pack: center;
            -ms-flex-pack: center;
            -webkit-justify-content: center;
            justify-content: center;
        }

        .swiper-pagination-bullet {
            @apply bg-white;

            &-active {
                opacity: 1;
            }
        }
    }
}